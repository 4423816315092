import Congrats from "../question-sequence/Congrats";
import MedicareMedicaid from "../question-sequence/MedicareMedicaid";
import Income from "../question-sequence/Income";
import CTA from "../question-sequence/Cta";
import DefaultPage from "../pages/default";
import React, { useEffect } from "react";
import { ROUTES } from "./Constant";

const RoutesArray = (cityStateResponse) => {
  const [number, setNumber] = React.useState("");
  const [subsidyAmount, setSubsidyAmount] = React.useState("$6,400");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramSubsidyAmount = urlParams.get("subsidyAmount");

    if (paramSubsidyAmount) {
      setSubsidyAmount(paramSubsidyAmount);
      sessionStorage.setItem("subsidyAmount", paramSubsidyAmount);
    } else {
      setSubsidyAmount(sessionStorage.getItem("subsidyAmount") || "$6,400");
    }
  }, []);
  return [
    {
      path: "/lander-1",
      element: (
        <DefaultPage
          cityStateResponse={cityStateResponse}
          setNumber={setNumber}
        />
      ),
      Children: [
        {
          path: "/lander-1",
          element: (
            <CTA
              subtitle={`Urgent for ${cityStateResponse.state}  Residents: Claim Your ${subsidyAmount}* Essential Support Subsidy in Just 3 Days!`}
              paragraph={`Secure up to ${subsidyAmount}* for groceries, rent, gas, and more, plus No-Cost Vision and Dental Care. A limited-time offer to elevate your lifestyle—apply today!`}
              headline={`Unlock Your ${subsidyAmount} Essential Subsidy Now!`}
            />
          ),
        },
        {
          path: ROUTES.income,
          element: <Income headline={`Congress Subsidy Program`} />,
        },
        {
          path: ROUTES.medicareMedicaid,
          element: <MedicareMedicaid headline={`Congress Subsidy Program`} />,
        },
        {
          path: ROUTES.congrats,
          element: (
            <Congrats
              headline={`You could be eligible for ${subsidyAmount} Subsidy.`}
              number={number}
            />
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        <DefaultPage
          cityStateResponse={cityStateResponse}
          setNumber={setNumber}
        />
      ),
      Children: [
        {
          path: "/",
          element: (
            <CTA
              subtitle={`Americans in ${cityStateResponse.state} have only 3 days to claim their FREE ${subsidyAmount}* Subsidy which they can use for Grocery, Rent, Gas and More!`}
              paragraph={`Thanks to a new program, ${cityStateResponse.state} residents could get up to ${subsidyAmount}* allowance for the everyday expense including No-Cost Vision & No-Cost Dental Benefits.`}
              headline={``}
            />
          ),
        },
        {
          path: ROUTES.income,
          element: <Income headline={`Congress Subsidy Program`} />,
        },
        {
          path: ROUTES.medicareMedicaid,
          element: <MedicareMedicaid headline={`Congress Subsidy Program`} />,
        },
        {
          path: ROUTES.congrats,
          element: (
            <Congrats
              headline={`You could be eligible for ${subsidyAmount} Subsidy.`}
              number={number}
            />
          ),
        },
      ],
    },
  ];
};
export default RoutesArray;
